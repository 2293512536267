
html,
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html,
body,
#root {
    margin: 0;
    padding: 0;
    height: 100%;

}

button,
.bnt {
    outline: none !important;
}

h1 {
    user-select: none;
}

/*table*/
tbody tr td {
    vertical-align: middle !important;
}

/*colors*/
.danger-color {
    color: #f86c6b;
}

.info-color {
    color: #63c2de;
}
